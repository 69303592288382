<template>
    <slot>
        <div class="mb-1">
            <label class="form-label" for="paymentMode">Mode of Payment</label>
            <v-select
                placeholder="Select Cash & Bank Account"
                class="w-100"
                v-model="formData.mop_account_head_id"
                name="mop_account_head_id"
                :options="cashAndBank"
                label="name"
                :reduce="name => name.id"
                :disabled="isDisabled"
            />
        </div>
        <div class="mb-1">
            <label class="form-label" for="mopReference">MoP Reference</label>
            <vField
                v-model="formData.reference"
                name="receipt_reference"
                type="text"
                class="form-control"
                :disabled="isDisabled"
            />
        </div>
    </slot>
</template>

<script setup>
import {computed, inject, onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import handleReceipt from "@/services/modules/receipt";
import handlePurchaseAndSales from "@/services/modules/purchase";


const $router = useRoute();
const {fetchCashAndBank} = handleReceipt();
const {fetchAccountReceivable} = handlePurchaseAndSales()
const showError = inject('showError')
const props = defineProps({
    formData: {
        type: Object
    },
    grandTotal: {
        type: Number
    },
    totalReturnAmount: {
        type: Number,
        default: null
    },
    isDisabled: {
        type: Boolean,
        default: false
    }
})

const companyId = $router.params.companyId;
const cashAndBank = ref([])
const accountReceivables = ref([])

const setInitialValue = (array, field) => {
    if (!array.length) return;
    props.formData[field] = array[0].id
}

const totalAmount = computed(() => props.grandTotal)

onMounted(() => {
    Promise.all([
        fetchCashAndBank(companyId).then(res => {
            if (res.data) cashAndBank.value = res.data
        }),
        fetchAccountReceivable(`?company_id=${companyId}`).then(res => {
            if (res.data) accountReceivables.value = res.data
        })
    ]).then(() => {
        setInitialValue(cashAndBank.value, 'mop_account_head_id');
        setInitialValue(accountReceivables.value, 'receivable_account_head_id');
    })
    .catch(() => {})
})
</script>