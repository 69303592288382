<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="true"
                :showAddNew="true"
                title="IPD Statement"
                btnTitle="Print"
                :showExtraBtn="false"
                @onClickAddNewButton="goToPrint"
                @onClickCloseButton="closeButton"
            />
        </div>

        <div v-if="patientDetails.patient" class="row p-1 px-2">
            <div class="col-md-6">
                <p>Patient Name: {{ patientDetails.patient.full_name }}</p>		
	            <p>Patient ID: {{ patientDetails.patient.patient_id }}</p>		
	            <p class="capitalize">
                    {{ 
                        (patientDetails.patient.birthday ? 'Age: ' + calculateAge(patientDetails.patient.birthday) : '') + 
                        (patientDetails.patient.gender ? ' | Sex: ' + patientDetails.patient.gender : '') 
                    }}
                </p>	
	            <p>Patient Phone No:	{{ patientDetails.patient.mobile_no }}</p>	
	            <p>Patient Address: {{ patientDetails.patient.full_address }}</p>		
            </div>

            <div class="col-md-6">
	            <p>IPD admission no: {{ patientDetails.admission_no }}</p>
	            <p>Admission date: {{ patientDetails.admission_date }}</p>
	            <p>Admission Dept.: {{ patientDetails.department ? patientDetails.department.name : 'N/A' }}</p>
	            <p>Bed/Cabin no: {{ patientDetails.service_resource.name }}</p>
	            <p>Consultant: {{ patientDetails.human_resource.name }}</p>
            </div>
        </div>

        <div class="col-12 px-2 mt-2 mb-5">
            <ListTable :service="service" :receiptGenerals="receiptGenerals" :total="total" :patientDetails="patientDetails" :companyInfo="companyInfo"/>
        </div>

        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
    import TitleButton from '@/components/atom/TitleButton';
    import ListTable from '@/components/molecule/company/hospital/ipd-station/IpdStatementTable.vue';
    import { useRoute, useRouter } from "vue-router";
    import { onMounted, ref } from 'vue'; 
    import handleHospital from '@/services/modules/hospital';
    import Loader from '@/components/atom/LoaderComponent';
    import ipdStatementHelper from '@/services/utils/pdf/ipdStatementHelper';
    import handleCompany from "@/services/modules/company";

    const $route = useRoute();
    const $router = useRouter();

    const { fetchIpdStatement, dateOfBarthConvertToAge } = handleHospital();
    const { generatePdf } = ipdStatementHelper();
    const { fetchCompanyInfo } = handleCompany();

    const patientDetails = ref({});
    const service = ref([]);
    const loading = ref(false);
    const receiptGenerals = ref([]);
    const total = ref({});
    const companyInfo = ref({});

    onMounted(() => {
        const query = `/${$route.query.id}?company_id=${$route.params.companyId}`;
        loading.value = true;

        Promise.all([
            fetchIpdStatement(query).then((res) => {
                patientDetails.value = res.data;
                service.value = res.service_data;
                receiptGenerals.value = res.receipt_generals;
                total.value = res.total;
                total.value.settlement_discount = res.data.settlement_discount;
            }),
            fetchCompanyInfo($route.params.companyId).then((res) => {
                companyInfo.value = res.data
            })
        ]).catch(err => {
            console.log(err)
        }).finally(() => {
            loading.value = false;
        })
    })

    const calculateAge = (birthday) => {
        const { year } = dateOfBarthConvertToAge(birthday)
        return `${year}y`
    }

    const goToPrint = () => {
        generatePdf(companyInfo.value, patientDetails.value, service.value, receiptGenerals.value, total.value)
    }

    const closeButton = () => {
        $router.back();
    }

</script>